import { Link } from 'react-router-dom'
import { NOTICE, PAYMENT_POLICY, POLICY, TERM } from '../../configs/path'
import './Footer.css'
import { IS_COMPANY } from '../../configs/constant'
import { useEffect, useState } from 'react'

function Footer() {
  const [query, setQuery] = useState<any>('')

  useEffect(() => {
    if (window.location.pathname === NOTICE || window.location.pathname === PAYMENT_POLICY) {
      setQuery(window.location.search)
    } else if (localStorage.getItem(IS_COMPANY) === 'true') {
      setQuery('?isCompany=true')
    } else setQuery('')
  }, [])

  return (
    <footer>
      <div className="link">
        <Link to={TERM}>利用規約</Link>｜
        <Link to={POLICY}>プライバシーポリシー</Link>｜
        <Link
          to={{
            pathname: NOTICE,
            search: query,
          }}
        >
          特定商取引法に基づく表記
        </Link>
        ｜
        <a href="mailto:info.pairla@gmail.com?subject=Pairlaに関するお問い合わせ&body=お問い合わせ内容：（詳細を記載してください）">
          お問い合わせ
        </a>
      </div>
      <div className="link-mobile">
        <Link to={TERM}>利用規約</Link>
        <Link to={POLICY}>プライバシーポリシー</Link>
        <Link
          to={{
            pathname: NOTICE,
            search: query,
          }}
        >
          特定商取引法に基づく表記
        </Link>
        <a href="mailto:info.pairla@gmail.com?subject=Pairlaに関するお問い合わせ&body=お問い合わせ内容：（詳細を記載してください）">
          お問い合わせ
        </a>
      </div>
      <div className="copyright">© pairla-video.com</div>
    </footer>
  )
}

export default Footer
